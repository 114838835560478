

td {
    padding: 5px;
    border: none;
}

th {
    text-align: right;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

.tdRight {
    text-align: right;
}
