.Spinner {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 100%;
    z-index: 99;
}
