root {
    --header-background-color: #939393;
    --header-background-color-odd: #93939355;
    --header-background-color-even: #93939399;
}

.cell_number {
    text-align: right;
    padding-right: 1em !important;
    white-space: normal !important;
    align-self: center;
}

.cell_string {
    text-align: left;
    padding-left: 1em !important;
    white-space: normal !important;
    align-self: center;
}

.header_cell_number {
    text-align: right;
    padding-left: 1em !important;
}

.header_cell_string {
    text-align: left;
    padding-left: 1em !important;
}

.subcell_number {
    text-align: right;
    padding-right: 1em !important;
    white-space: normal !important;
    align-self: right;
}

.subcell_string {
    text-align: left;
    padding-left: 1em !important;
    white-space: normal !important;
    align-self: left;
}

.subheader_cell_number {
    text-align: right;
    padding-left: 1em !important;
    background-color: var(--header-background-color);
    color: #ffffff;
}

.subheader_cell_string {
    text-align: left;
    padding-left: 1em !important;
    background-color: var(--header-background-color);
    color: #ffffff;
}

.subheader_title {
    background-color: var(--header-background-color);
    color: #ffffff;
}

.text-center {
    text-align: center;
}

.rt-tr-group .rt-tr-group .-odd {
    background-color: var(--header-background-color-odd) !important;
}

.rt-tr-group .rt-tr-group .-even {
    background-color: var(--header-background-color-even) !important;
}

.linearProgressCustom {
    height: 10px !important;
    border-radius: 5px;
    margin-bottom: 2px;
}
